
import React from 'react'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';



function ExpansionSummary() {


  return (

      <div className="ExpansionSummaryContainer">
        <h1>Expansion summary</h1>
        <div className="ExpansionSummaryBody">

            <li><FiberManualRecordRoundedIcon /> New 9,700 SF rooftop space with 5,700 rooftop field for community programs computing laboratory pdf</li>
            <li><FiberManualRecordRoundedIcon /> New 9,700 SF floor with 12 new & larger classrooms</li>
            <li><FiberManualRecordRoundedIcon /> New 6,500 SF NBA size gymnasium with bleachers</li>
            <li><FiberManualRecordRoundedIcon /> New 2,400 SF sister's prayer hall</li>
            <li><FiberManualRecordRoundedIcon /> New 1,150 SF cafeteria</li>
            <li><FiberManualRecordRoundedIcon /> New 1,000 SF multipurpose hall</li>
            <li><FiberManualRecordRoundedIcon /> New creative computing laboratory and multipurpose laboratory</li>
            <li><FiberManualRecordRoundedIcon /> New restrooms on gymnasium level and first floor</li>
            <li><FiberManualRecordRoundedIcon /> New elevator serving gymnasium, first and second floors</li>

        </div>

      </div>



  );
}










export default ExpansionSummary