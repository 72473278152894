
import React from 'react'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import HandIcon from '../assets/imgs/HandIcon.svg'


import MasjidImg_1 from '../assets/imgs/MasjidTauheed_NorthView.png'
import MasjidImg_2 from '../assets/imgs/MasjidTauheed_SouthSide.png'
import MasjidImg_3 from '../assets/imgs/MasjidTauheed_SouthView.png'
import MasjidImg_4 from '../assets/imgs/MasjidTauheed_SouthElevation.png'



const ConstructionUpdates = (props) => {

  return (

    <div className="ExpansionConstructionUpdatesContainer LighGreyContainer">
      <div id="Updates" className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Project Updates</h1>
        <div className="MobileScroll">
        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className="TimeLineDate">DEC 2020</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Accordion>

              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className="accourdianTitle" >We initiated our re-construction project, Alhamdulillah</Typography>
              </AccordionSummary>

              <AccordionDetails>

                {/* <div className="autherName">by Khalil Ahmad</div> */}

                <div className="ConstructionUpdatesGallery">
                </div>

              </AccordionDetails>
            </Accordion></TimelineContent>
          </TimelineItem>
        </Timeline>


        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>
              <Typography className="TimeLineDate">APR 2021</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent><Accordion defaultExpanded>

              <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                <Typography className="accourdianTitle" >Site plan submitted to City of North Miami for approval.</Typography>
              </AccordionSummary>

              <AccordionDetails>

                {/* <div className="autherName">by Khalil Ahmad</div> */}

                <div className="ConstructionUpdatesGallery">

                  <Grid container spacing={1}>

                  <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={MasjidImg_4} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={MasjidImg_1} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={MasjidImg_2} />
                      </div>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <div className="ConstructionUpdatesPhotoBox">
                        <img className="ConstructionUpdatesPhoto" src={MasjidImg_3} />
                      </div>
                    </Grid>

                    



                  </Grid>
                </div>

              </AccordionDetails>
            </Accordion></TimelineContent>
          </TimelineItem>
        </Timeline>



        
        </div>

        <div className="ButtonCenter groupButton">
              <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/masjid-tauheed">
              <img className="ExpansionFillButtonIcon" src={HandIcon} /> Support us now</Button>

               {/* <Button className="ExpansionFillButton white" variant="contained" href="#contained-buttons">SHOW PAST updates</Button> */}
            </div>
      </div>
    </div>



  );
}


export default ConstructionUpdates